import axios from 'axios'
export default {
  async show() {
    return new Promise((resolve, reject) => {
      axios
        .get('/privacy')
        .then(res => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async update(data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`privacy`, data)
        .then(res => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}